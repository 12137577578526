import "@hotwired/turbo-rails"
import "../common_controllers"

// import * as Turbo from '@hotwired/turbo'
// Turbo.start()

import "react_ujs";
// const context = import.meta.globEager('../components/*.{js,jsx}');
// Object.keys(context).forEach((path) => {
//   let component = context[path].default;
//
//   `import * as ${ component.name } from '${ path }'`;
//
//   window[component.name] = component;
// });
import ContactUs from '../components/ContactUs.jsx'
window['ContactUs'] = ContactUs;


import "../../../vendor/theme/uniland/assets/webfonts/flaticon/flaticon.css";
import "../../../vendor/theme/uniland/assets/css/bootstrap.min.css";
import "../../../vendor/theme/uniland/assets/css/bootstrap-select.min.css";
import "../../../vendor/theme/uniland/assets/css/all.min.css";
import "../../../vendor/theme/uniland/assets/css/animate.min.css";
import "../../../vendor/theme/uniland/assets/css/owl.css";
// import "../../../vendor/theme/uniland/assets/css/colors/color_5.css";
import "../../../vendor/theme/uniland/assets/css/jquery.fancybox.min.css";
import "../../../vendor/theme/uniland/assets/css/layerslider.css";
import "../../../vendor/theme/uniland/assets/css/template.css";
import "../../../vendor/theme/uniland/assets/css/style.css";
import "../../../vendor/theme/uniland/assets/css/loader.css";

function handlePreloader() {
  if ($('.preloader').length) {
    $('.preloader').delay(500).fadeOut(100);
  }

  if ($('#single-property').length) {
    $('#single-property').layerSlider({
      sliderVersion: '6.5.0b2',
      type: 'popup',
      pauseOnHover: 'disabled',
      skin: 'photogallery',
      globalBGSize: 'cover',
      navStartStop: false,
      hoverBottomNav: true,
      showCircleTimer: false,
      thumbnailNavigation: 'always',
      tnContainerWidth: '100%',
      tnHeight: 70,
      popupShowOnTimeout: 1,
      popupShowOnce: false,
      popupCloseButtonStyle: 'background: rgba(0,0,0,.5); border-radius: 2px; border: 0; left: auto; right: 10px;',
      popupResetOnClose: 'disabled',
      popupDistanceLeft: 20,
      popupDistanceRight: 20,
      popupDistanceTop: 20,
      popupDistanceBottom: 20,
      popupDurationIn: 750,
      popupDelayIn: 500,
      popupTransitionIn: 'scalefromtop',
      popupTransitionOut: 'scaletobottom',
      skinsPath: '/uniland/assets/skins/'
    });
  }
}

document.addEventListener("turbo:load", handlePreloader)
