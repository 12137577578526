import Transformer from "./Transformer";

export class ContactTransformer extends Transformer {
  static fetch() {
    return {};
  }

  static send(contact) {
    console.log(contact)
    const name = contact.fullName.split(' ').slice(0, -1).join(' ')
    const surname = contact.fullName.split(' ').slice(-1).join(' ')
    return {
      name,
      surname,
      email: contact.email,
      title: contact.title,
      message: contact.message,
    };
  }
}
