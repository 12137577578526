import React, {useEffect, useState} from "react"
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {ContactTransformer} from "../transformers/ContactTransformer";

const schema = (i18n) => yup.object({
  fullName: yup.string().required(i18n.fullName.required),
  email: yup.string().email(i18n.email.valid).max(255).required(i18n.email.required),
  title: yup.string().required(i18n.title.required),
  message: yup.string().required(i18n.message.required),
}).required();

function getMessage(field) {
  return field && field.message;
}

const ContactUs = ({resourceId, resourceType, i18n}) => {
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(schema(i18n.validation))
  });
  const [isSend, setIsSend] = useState(false)

  async function submitContact(data) {
    fetch('/contacts', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
      },
      body: JSON.stringify({
        contact: {
          name: data.name,
          surname: data.surname,
          email: data.email,
          title: data.title,
          message: data.message,
          resource_id: resourceId,
          resource_type: resourceType,
        }
      })
    })
    .then(() => {
      alert("Your message is sent!")
      setIsSend(true)
    })
  }

  const onSubmit = (data) => {
    submitContact(ContactTransformer.send(data))
  };

  return <form onSubmit={handleSubmit(onSubmit)} className="">
      <div className="row">
        <div className="col-md-6 mb-20">
          <label className="mb-2">{i18n.fullName}:</label>
          <input type="text" className={`form-control bg-gray ${errors.fullName ? 'is-invalid' : ''}`} {...register('fullName')}/>
          <div className="invalid-feedback">
            {getMessage(errors.fullName)}
          </div>
        </div>
        <div className="col-md-6 mb-20">
          <label className="mb-2">{i18n.yourEmailAddress}:</label>
          <input type="email" className={`form-control bg-gray ${errors.email ? 'is-invalid' : ''}`} {...register('email')}/>
          <div className="invalid-feedback">
            {getMessage(errors.email)}
          </div>
        </div>
        <div className="col-md-12 mb-20">
          <label className="mb-2">{i18n.title}:</label>
          <input type="text" className={`form-control bg-gray ${errors.title ? 'is-invalid' : ''}`} {...register('title')}/>
          <div className="invalid-feedback">
            {getMessage(errors.title)}
          </div>
        </div>
        <div className="col-md-12 mb-20">
          <label className="mb-2">{i18n.yourMessage}:</label>
          <textarea className={`form-control bg-gray ${errors.message ? 'is-invalid' : ''}`} rows="8" {...register('message')}></textarea>
          <div className="invalid-feedback">
            {getMessage(errors.message)}
          </div>
        </div>
        <div className="col-md-12">
          <button className="btn btn-primary" name="submit" type="submit">{i18n.submit}</button>
        </div>
      </div>
    </form>
}
export default ContactUs
